"use client";

import { FC, useCallback, useEffect, useRef, useState } from "react";
import cx from "classnames";
import styles from "./header-desktop-submenu-alternative.module.scss";
import { isAbsoluteUrl } from "next/dist/shared/lib/utils";
import { ButtonAction, MenuItem, RouteType, SpecialMenuType, SubmenuItem } from "../../../types/site";
import { handleButtonAction } from "../../../utils/ui";
import Button from "../../common-components/button";
import { isMenuActive } from "./header-desktop";
import { IHeaderDesktopSubmenu } from "./header-desktop-submenu";
import { ModelCount } from "../../../types/ModelInfo";
import { smartFormat } from "../../../utils/stringFormatter";
import { sendAnalyticsEvent } from "../../../utils/analitics";
import { usePathname } from "next/navigation";
import CustomScroll, { $vSrollPostionValues } from "../custom-scroll/custom-scroll";

const SHOW_MENU_DELAY_MS = 150;

const HeaderDesktopSubmenuAlternative: FC<IHeaderDesktopSubmenu> = ({ headerMenu, baseUrl, newModels }) => {
  const [secondLevelMenu, setSecondLevelMenu] = useState<MenuItem | undefined>(undefined);
  const [timer, setTimer] = useState<any>(undefined);
  let pagePath = usePathname();
  if (pagePath.startsWith("/blog-post")) {
    pagePath = "/blog";
  }
  const [activeMenu, setActiveMenu] = useState<string | undefined>(pagePath);

  useEffect(() => setActiveMenu(pagePath), [pagePath]);

  const handleMenuButton = useCallback((button: ButtonAction, topMenu: string) => {
    sendAnalyticsEvent("asc_menu_interaction", {
      element_text: button.title,
      element_type: topMenu,
      event_action: "click",
      element_subtype: "nav_button",
    });
    handleButtonAction(button);
  }, []);

  const handleLinkClick = useCallback((text: string, topMenu: string, path: string, hasImage: boolean) => {
    sendAnalyticsEvent("asc_menu_interaction", {
      element_text: text,
      element_type: topMenu,
      event_action: "click",
      element_subtype: hasImage ? "nav_icon" : "hyperlink",
    });
    if (path.length) {
      setSecondLevelMenu(undefined);
      setActiveMenu(path);
    }
  }, []);

  const path = usePathname();
  const urlFormat = "/search-result-page/new-vehicles?make={make}&model={model}";
  const currentPagePath = path.replace("%20", " ");
  const parentMenuText = secondLevelMenu?.title;

  const handleOnImageLoadError = (e: any) => {
    e.target.src = "/images/out-of-stock-left.png";
  };

  const onModelClick = useCallback(
    (text: string) => {
      sendAnalyticsEvent("asc_menu_interaction", {
        element_text: text,
        element_type: parentMenuText,
        event_action: "click",
        element_subtype: "nav_icon",
      });
    },
    [parentMenuText],
  );

  const submenuRef = useRef<HTMLDivElement>(null);

  const defineIfModelsScrolling = useCallback(() => {
    const customScroll = document.querySelector("#appScroll") as HTMLDivElement;

    if (
      secondLevelMenu &&
      submenuRef.current &&
      secondLevelMenu?.specialMenu === SpecialMenuType.NEW_MODELS &&
      customScroll
    ) {
      customScroll.style.pointerEvents = "hidden";
    } else {
      if (customScroll) {
        customScroll.style.pointerEvents = "inherit";
      }
    }
  }, [secondLevelMenu]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      defineIfModelsScrolling();
      window.addEventListener("resize", defineIfModelsScrolling);

      return () => window.removeEventListener("resize", defineIfModelsScrolling);
    }
  }, [defineIfModelsScrolling]);

  const calculateImageUrl = useCallback(
    (item: ModelCount) => `/images/models/${item?.make.toLowerCase()}/${item?.model.toLowerCase()}_hover.webp`,
    [],
  );
  return (
    <nav className={styles.navigation} onMouseLeave={() => setSecondLevelMenu(undefined)}>
      {headerMenu.map((menuItem: MenuItem, index: number) => (
        <div
          onMouseEnter={() => {
            setTimer(
              setTimeout(() => {
                if (index !== undefined) {
                  setSecondLevelMenu(headerMenu[index]);
                } else {
                  setSecondLevelMenu(undefined);
                }
              }, SHOW_MENU_DELAY_MS),
            );
          }}
          onMouseLeave={() => {
            if (timer) {
              clearTimeout(timer);
              setTimer(undefined);
            }
          }}
          key={menuItem.id + index}
          className={styles.linkContainer}
        >
          <a
            href={menuItem.path}
            onClick={() => handleLinkClick(menuItem.title, menuItem.title, menuItem.path!, false)}
            target={
              isAbsoluteUrl(menuItem.path!) || menuItem.routeType === RouteType.NEW_WINDOW
                ? "noopener nofollow"
                : "_self"
            }
            className={cx(styles.link, {
              [styles.link__active]: isMenuActive(menuItem, activeMenu),
            })}
          >
            <span>{menuItem.title}</span>
          </a>
          {headerMenu[index].submenu?.length! > 0 && (
            <div
              ref={headerMenu[index]?.specialMenu === SpecialMenuType.NEW_MODELS ? submenuRef : undefined}
              className={cx(styles.submenuContainer, {
                [styles.active]: secondLevelMenu === headerMenu[index],
                [styles.newModels]: headerMenu[index]?.specialMenu === SpecialMenuType.NEW_MODELS,
              })}
            >
              <ul className={styles.subcategory}>
                {headerMenu[index].submenu?.map((submenuItem: SubmenuItem, submenuIndex: number) =>
                  submenuItem.buttonMode ? (
                    <Button
                      key={submenuIndex}
                      name={"subcategoryMenuButton"}
                      ariaLabel={submenuItem?.title}
                      type="secondary"
                      text={submenuItem?.title || ""}
                      className={styles.subcategoryBtn}
                      onClick={() => handleMenuButton(submenuItem, headerMenu[submenuIndex].title)}
                    />
                  ) : (
                    <li
                      className={styles.subcategoryItem}
                      key={submenuItem.id + submenuIndex}
                      onClick={() =>
                        handleLinkClick(
                          submenuItem.title,
                          headerMenu[submenuIndex]?.title,
                          submenuItem.path!,
                          !!submenuItem.image,
                        )
                      }
                    >
                      <a
                        href={submenuItem.path}
                        target={
                          isAbsoluteUrl(submenuItem.path!) || submenuItem.routeType === RouteType.NEW_WINDOW
                            ? "noopener nofollow"
                            : "_self"
                        }
                        title={submenuItem.title}
                        className={cx(styles.subcategoryLink, {
                          [styles.subcategoryLinkActive]: submenuItem.path === activeMenu,
                          [styles.subcategoryLinkWithImage]: submenuItem.image,
                        })}
                      >
                        {submenuItem?.image && (
                          <img
                            className={styles.subcategoryLinkImage}
                            src={baseUrl + submenuItem?.image || "/images/out-of-stock.png"}
                            alt={submenuItem?.title}
                          />
                        )}
                        {submenuItem?.title && <span className={styles.subcategoryLinkText}>{submenuItem.title}</span>}
                      </a>
                    </li>
                  ),
                )}
              </ul>
              {headerMenu[index]?.specialMenu === SpecialMenuType.NEW_MODELS && newModels?.length > 0 && (
                <div className={styles.shopMenu}>
                  <CustomScroll $vScrollPosition={$vSrollPostionValues.MEDIUM}>
                    <div className={styles.shopMenuContainer}>
                      {newModels?.map((item: ModelCount, newModelIndex: number) => (
                        <a
                          key={newModelIndex}
                          href={smartFormat(urlFormat, item)}
                          className={styles.sliderLink}
                          onClick={() => onModelClick(item.model)}
                        >
                          <img
                            src={calculateImageUrl(item) || "/images/out-of-stock-left.png"}
                            onError={handleOnImageLoadError}
                            height={78}
                            width={155}
                            alt={item.model}
                            className={styles.modelsImage}
                            loading="lazy"
                          />
                          <div
                            className={cx(styles.sliderTitle, {
                              [styles.sliderTitleSelected]: currentPagePath === smartFormat(urlFormat, item),
                            })}
                          >
                            <p title={item.model}>{item.model}</p>
                            <span className={styles.sliderTitleCount}>{item.count}</span>
                          </div>
                        </a>
                      ))}
                    </div>
                  </CustomScroll>
                </div>
              )}
            </div>
          )}
        </div>
      ))}
    </nav>
  );
};

export default HeaderDesktopSubmenuAlternative;
