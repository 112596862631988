"use client";

import { FC, useCallback, useEffect, useState } from "react";
import cx from "classnames";
import styles from "./header-desktop-submenu.module.scss";
import { isAbsoluteUrl } from "next/dist/shared/lib/utils";
import { ButtonAction, LogoPosition, MenuItem, RouteType, SubmenuItem } from "../../../types/site";
import { handleButtonAction } from "../../../utils/ui";
import Button from "../../common-components/button";
import HeaderDesktopSlider from "./header-desktop-slider";
import { isMenuActive } from "./header-desktop";
import { RequestContext } from "../../../types/ui";
import { ModelCount } from "../../../types/ModelInfo";
import { usePathname } from "next/navigation";
import { sendAnalyticsEvent } from "../../../utils/analitics";

export interface IHeaderDesktopSubmenu {
  headerMenu: MenuItem[];
  baseUrl: string;
  newModels: ModelCount[];
  logoPosition: LogoPosition;
  context: RequestContext;
}

const SHOW_MENU_DELAY_MS = 150;

const HeaderDesktopSubmenu: FC<IHeaderDesktopSubmenu> = ({ headerMenu, baseUrl, newModels, logoPosition }) => {
  const [secondLevelMenu, setSecondLevelMenu] = useState<MenuItem | undefined>(undefined);
  const [timer, setTimer] = useState<any>(undefined);
  let pagePath = usePathname();
  if (pagePath.startsWith("/blog-post")) {
    pagePath = "/blog";
  }
  const [activeMenu, setActiveMenu] = useState<string | undefined>(pagePath);

  useEffect(() => setActiveMenu(pagePath), [pagePath]);

  const handleMenuButton = useCallback((button: ButtonAction, topMenu: string) => {
    sendAnalyticsEvent("asc_menu_interaction", {
      element_text: button.title,
      element_type: topMenu,
      event_action: "click",
      element_subtype: "nav_button",
    });
    handleButtonAction(button);
  }, []);

  const handleLinkClick = useCallback((text: string, topMenu: string, path?: string, hasImage?: boolean) => {
    sendAnalyticsEvent("asc_menu_interaction", {
      element_text: text,
      element_type: topMenu,
      event_action: "click",
      element_subtype: hasImage ? "nav_icon" : "hyperlink",
    });
    if (path?.length) {
      setSecondLevelMenu(undefined);
      setActiveMenu(path);
    }
  }, []);

  return (
    <nav className={styles.navigation} onMouseLeave={() => setSecondLevelMenu(undefined)}>
      {headerMenu.map((menuItem: MenuItem, index: number) => (
        <div
          onMouseEnter={() => {
            setTimer(
              setTimeout(() => {
                if (index !== undefined) {
                  setSecondLevelMenu(headerMenu[index]);
                } else {
                  setSecondLevelMenu(undefined);
                }
              }, SHOW_MENU_DELAY_MS),
            );
          }}
          onMouseLeave={() => {
            if (timer) {
              clearTimeout(timer);
              setTimer(undefined);
            }
          }}
          key={menuItem.id + index}
          className={styles.linkContainer}
        >
          <a
            href={menuItem.path}
            onClick={() => handleLinkClick(menuItem.title, menuItem.title, menuItem.path, false)}
            target={
              isAbsoluteUrl(menuItem.path!) || menuItem.routeType === RouteType.NEW_WINDOW
                ? "noopener nofollow"
                : "_self"
            }
            className={cx(styles.link, {
              [styles.link__active]: isMenuActive(menuItem, activeMenu),
            })}
          >
            {menuItem.title}
          </a>
        </div>
      ))}
      <div className={styles.submenuContainer}>
        {secondLevelMenu?.submenu?.length! ? (
          <div className={styles.category}>
            <ul className={styles.subcategory}>
              {secondLevelMenu?.submenu?.map((submenuItem: SubmenuItem, index: number) =>
                submenuItem.buttonMode ? (
                  <Button
                    key={index}
                    name={"subcategoryMenuButton"}
                    ariaLabel={submenuItem.title}
                    type="secondary"
                    text={submenuItem.title}
                    className={styles.subcategoryBtn}
                    onClick={() => handleMenuButton(submenuItem, secondLevelMenu.title)}
                  />
                ) : (
                  <li
                    className={styles.subcategoryItem}
                    key={submenuItem.id + index}
                    onClick={() =>
                      handleLinkClick(submenuItem.title, secondLevelMenu.title, submenuItem.path, !!submenuItem.image)
                    }
                  >
                    <a
                      href={submenuItem.path}
                      target={
                        isAbsoluteUrl(submenuItem.path!) || submenuItem.routeType === RouteType.NEW_WINDOW
                          ? "noopener nofollow"
                          : "_self"
                      }
                      title={submenuItem.title}
                      className={cx(styles.subcategoryLink, {
                        [styles.subcategoryLinkActive]: submenuItem.path === activeMenu,
                        [styles.subcategoryLinkWithImage]: submenuItem.image,
                      })}
                    >
                      {submenuItem?.image && (
                        <img
                          className={styles.subcategoryLinkImage}
                          src={baseUrl + submenuItem?.image || "/images/out-of-stock.png"}
                          alt={submenuItem?.title}
                          loading="lazy"
                        />
                      )}
                      {submenuItem?.title && <span className={styles.subcategoryLinkText}>{submenuItem.title}</span>}
                    </a>
                  </li>
                ),
              )}
            </ul>
          </div>
        ) : (
          <></>
        )}
        {secondLevelMenu?.specialMenu === "new_models" && newModels?.length > 0 ? (
          <div className={styles.shop_menu}>
            <HeaderDesktopSlider
              urlFormat={"/search-result-page/new-vehicles?make={make}&model={model}"}
              newModels={newModels}
              parentMenuText={secondLevelMenu.title}
              logoPosition={logoPosition}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </nav>
  );
};

export default HeaderDesktopSubmenu;
